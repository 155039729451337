body,
html {
  direction: rtl;
  background: #1b1b22;
  color: white;
}

@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 10;
  src: url("../fonts/woff2/Dana-Hairline.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Hairline.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 100;
  src: url("../fonts/woff2/Dana-Thin.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Thin.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/woff2/Dana-UltraLight.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-UltraLight.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/woff2/Dana-Light.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Light.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/woff2/Dana-Medium.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Medium.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/woff2/Dana-DemiBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-DemiBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/woff2/Dana-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-ExtraBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/woff2/Dana-Black.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Black.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 930;
  src: url("../fonts/woff2/Dana-ExtraBlack.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-ExtraBlack.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 960;
  src: url("../fonts/woff2/Dana-Heavy.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Heavy.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: 990;
  src: url("../fonts/woff2/Dana-fat.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-fat.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/woff2/Dana-ExtraBold.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-ExtraBold.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}
@font-face {
  font-family: dana;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/woff2/Dana-Regular.woff2") format("woff2"),
    /* FF39+,Chrome36+, Opera24+*/ url("../fonts/woff/Dana-Regular.woff")
      format("woff"); /* FF3.6+, IE9, Chrome6+, Saf5.1+*/
}

body {
  font-family: dana !important;
}
