@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");

.price,
.fa-num {
  -moz-font-feature-settings: "ss02";
  -webkit-font-feature-settings: "ss02";
  font-feature-settings: "ss02";
}

tr[role="row"] {
  th {
    &:first-child {
      @apply rounded-r-lg;
    }
    &:last-child {
      @apply rounded-l-lg;
    }
  }
  &:first-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-tr-lg;
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-tl-lg;
      }
    }
  }
  &:last-child {
    td {
      &:first-child {
        @apply border-gray-200;

        @apply rounded-br-lg;
      }
      &:last-child {
        @apply border-gray-200;

        @apply rounded-bl-lg;
      }
    }
  }
}

table tbody:before {
  content: "@";
  display: block;
  line-height: 20px;
  text-indent: -99999px;
  background: #181717;
}

.font-inter {
  font-family: "Inter", sans-serif;
}

.gradient {
  background: linear-gradient(to right, #6e5be7, #fcd34d);
}

.root {
  height: 100%;
}

.sketch-picker {
  @apply bg-secondary #{!important};

  input {
    @apply bg-light-blue border-light-blue border-0 rounded-6px hover:checked:bg-indigo-600 focus:checked:bg-indigo-600 focus:checked:ring-offset-secondary focus:ring-offset-secondary checked:bg-indigo-500 ring-indigo-500 focus:ring-indigo-500 outline-none shadow-none #{!important};
  }

  label {
    @apply text-white opacity-80 #{!important};
  }
}

.altered-scrollbar {
  &::-webkit-scrollbar {
    width: 0px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #282834;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #333341;
  }
}

input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  cursor: ew-resize;
  background: #fff;
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
}

.widget-container .widget-content {
  user-select: none;
  pointer-events: none;

  * {
    user-select: none;
    pointer-events: none;
  }
}

.bg-ps-transparent {
  background-color: #21212c;
  background-image: linear-gradient(
      45deg,
      #17171d 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #17171d 75%,
      #17171d
    ),
    linear-gradient(
      45deg,
      #17171d 25%,
      rgba(239, 239, 239, 0) 25%,
      rgba(239, 239, 239, 0) 75%,
      #17171d 75%,
      #17171d
    );
  background-position: 0 0, 10px 10px;
  background-size: 21px 21px;
}

.bg-editor {
  background-image: radial-gradient(#292936 1px, transparent 0);
  background-size: 13px 13px;
  background-position: -19px -19px;
}
