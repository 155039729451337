:root {
  --ship-size: 30px;
  --sky-color: #1c1740;
  --ship-color: #f9e2fe;
  --ship-cap-color: crimson;
  --ship-wing-color: #4c3198;
  --ship-window-trim-color: #4c3198;
  --ship-booster-color: #c38382;
  --star-color: white;
  --stars-sm-speed: 5s;
  --stars-md-speed: 3s;
  --stars-lg-speed: 2s;
}
.faucet {
  position: absolute;
  width: 100%;
  text-align: center;
  z-index: 9999;
  margin-left: -50px;
  margin-top: 20px;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}

.mainholder {
  margin: auto;
  display: flex;
  flex-direction: column;
  background: #1c1740;
  border-radius: 40px;
  padding-top: 30px;
  overflow: hidden;
  width: 100%;
  position: relative;
  height: 100%;
  box-sizing: border-box;
}
.mainholder .timer {
  font-size: 50px;
  line-height: 60px;
  font-family: monospace;
  text-align: center;
  text-shadow: 0px 5px 10px rgba(57, 49, 48, 0.62);
}
.mainholder .timer div {
  display: inline;
}
.mainholder .timer .seperator {
  padding: 0 20px;
  font-weight: bold;
}
.mainholder .timer.timer-add-animation {
  animation: timeadd 0.5s;
}
.mainholder .addtimeholder {
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 50%;
  top: 120px;
  width: 500px;
  height: 150px;
  transform: translateX(-50%);
  z-index: 2;
}
.mainholder .addtimeholder .item {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  opacity: 0;
  color: #ffeb3b;
  animation: addedtimeanim 3s ease-out;
  font-size: 50px;
  font-family: monospace;
}
.mainholder .clockholder {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  top: 0;
}
.mainholder .clockholder .clock {
  height: 100%;
  width: 100%;
  z-index: 10;
  position: relative;
  display: none;
}
.mainholder .clockholder .waitingholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
}
.mainholder .clockholder .waitingholder .waiting {
  height: 520px;
  width: 280px;
  position: absolute;
  left: 50%;
  top: 35px;
  transform: translateX(-50%);
  mask: url(https://i.ibb.co/stDZzcW/inside-mask.png);
  -webkit-mask: url(https://i.ibb.co/stDZzcW/inside-mask.png);
}
.mainholder .clockholder .waitingholder .waiting img {
  transition: all 0.5s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 277px;
  margin-top: -30px;
}

.mainholder .clockholder .filledholder {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 50%;
  overflow: hidden;
  border-top: 2px dashed #ffffff45;
}
.mainholder .clockholder .filledholder .filled {
  height: 520px;
  width: 100%;
  position: absolute;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%);
  overflow: hidden;
  z-index: 9;
}
.mainholder .clockholder .filledholder .filled .water-container {
  transition: all 0.5s;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  margin-top: 520px;
  /*background: blue;*/
  height: 255px;
  display: block;
  width: 100%;
}
.mainholder .clockholder .sandholder {
  transition: all 1s;
  height: calc(90% - 35px);
  width: 15px;
  position: absolute;
  left: 50%;
  top: 124px;
  opacity: 0;
  transform: translateX(-50%);
}
.mainholder .clockholder .sandholder .falling-sand {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #4d6de3;
  position: absolute;
  opacity: 0;
  top: 0;
}
.no-anim {
  animation: none !important;
}
@keyframes falling {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(570px);
  }
}
@keyframes timeadd {
  0%,
  100% {
    color: #fff;
    transform: scale(1);
  }
  20% {
    color: #ffeb3b;
    transform: scale(1.25);
  }
}
@keyframes addedtimeanim {
  0%,
  95% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: 30px;
  }
}

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.ship .wrapper {
  display: flex;
}

.ship .body {
  position: relative;
  background-color: var(--ship-color);
  border-radius: 0 0 50% 50%/76% 76% 15% 15%;
}
.ship .body:before {
  content: "";
  position: absolute;
  border-radius: 50% 50% 50% 50%/76% 76% 25% 25%;
}

.ship .main {
  width: var(--ship-size);
  height: calc(var(--ship-size) * 1.5);
  box-shadow: inset rgba(0, 0, 0, 0.15) -0.5vmin 0 2vmin 0;
}
.ship .main:before {
  bottom: 80%;
  width: 100%;
  height: 75%;
  background-color: inherit;
  box-shadow: inset rgba(0, 0, 0, 0.15) -0.5vmin 1vmin 1vmin 0;
}
.ship .window {
  content: "";
  position: absolute;
  bottom: 75%;
  left: 0;
  right: 0;
  margin: auto;
  border: calc(var(--ship-size) / 15) solid var(--ship-window-trim-color);
  width: calc(var(--ship-size) / 1.4);
  height: calc(var(--ship-size) / 1.4);
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ship .window:after {
  content: "";
  width: 100%;
  height: 100%;
  box-shadow: inset rgb(255 255 255 / 28%) -60px -60px 0 0,
    inset rgb(76 49 152 / 18%) -30px -45px 0 0;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
}

.ship .window img {
  width: 200%;
  margin-top: 10px;
  margin-right: 5px;
}

.ship .side {
  width: calc(var(--ship-size) / 3);
  height: var(--ship-size);
  box-shadow: inset rgba(0, 0, 0, 0.1) -0.5vmin 0 1vmin 0,
    inset rgba(0, 0, 0, 0.1) 0.5vmin 0 1vmin 0;
}
.ship .side:before {
  bottom: 90%;
  width: 100%;
  height: 35%;
  background-color: var(--ship-cap-color);
  box-shadow: inset rgba(0, 0, 0, 0.2) -0.5vmin 1vmin 1vmin 0,
    inset rgba(255, 255, 255, 0.2) 0.5vmin 1vmin 1vmin 0;
}
.ship .side.left {
  left: 1px;
}
.ship .side.right {
  right: 1px;
}

.ship .wing {
  position: absolute;
  bottom: 2vmin;
  background-color: var(--ship-wing-color);
  width: calc(var(--ship-size) / 2);
  height: calc(var(--ship-size) / 1.5);
  z-index: 1;
  box-shadow: inset rgba(0, 0, 0, 0.1) -0.5vmin 1vmin 1vmin 0,
    inset rgba(255, 255, 255, 0.1) 0.5vmin 1vmin 1vmin 0;
}
.ship .wing.left {
  right: 100%;
  border-radius: 100% 0 10% 10%;
}
.ship .wing.right {
  left: 100%;
  border-radius: 0 100% 10% 10%;
}

.ship .booster {
  position: absolute;
  top: 80%;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(var(--ship-size) / 1.2);
  height: calc(var(--ship-size) / 2.5);
  background-color: var(--ship-booster-color);
  border-radius: 0 0 50% 50%/76% 76% 35% 35%;
  z-index: -1;
  box-shadow: inset rgba(0, 0, 0, 0.3) -0.5vmin 1vmin 1vmin 0,
    inset rgba(255, 255, 255, 0.3) 0.5vmin 1vmin 1vmin 0, black 0 0 2vmin;
}

.ship .exhaust {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(var(--ship-size) / 1.4);
  height: 80%;
  border-radius: 0 0 100% 100%;
  background-image: linear-gradient(to bottom, yellow, transparent 70%);
  z-index: -2;
  transform-origin: 50% 0;
  animation: exhaust 0.1s linear alternate infinite;
}

.star-field {
  width: 100%;
  height: 100%;
  transition: transform 1s ease-out;
  zoom: 1;
}

.ship,
.star-field {
  position: absolute;
  top: 50%;
  left: 50%;
  will-change: transform;
  transition: transform 0.4s ease;
  transform: translate(-50%, -50%) rotate(90deg);
}

.stars {
  position: absolute;
  top: 0;
  left: 0;
}
.stars:before,
.stars:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  animation: stars linear infinite;
  transform: translateY(-100vh);
}
.stars-sm {
  width: 2px;
  height: 2px;
}
.stars-sm:before,
.stars-sm:after {
  box-shadow: 120vw 153vh var(--star-color), 126vw 7vh var(--star-color),
    134vw 11vh var(--star-color), 120vw 112vh var(--star-color),
    51vw 10vh var(--star-color), 162vw 178vh var(--star-color),
    61vw 51vh var(--star-color), 49vw 179vh var(--star-color),
    162vw 50vh var(--star-color), 149vw 105vh var(--star-color),
    56vw 30vh var(--star-color), 92vw 188vh var(--star-color),
    130vw 147vh var(--star-color), 148vw 146vh var(--star-color),
    162vw 1vh var(--star-color), 29vw 97vh var(--star-color),
    62vw 15vh var(--star-color), 51vw 170vh var(--star-color),
    90vw 100vh var(--star-color), 36vw 111vh var(--star-color),
    52vw 13vh var(--star-color), 88vw 132vh var(--star-color),
    168vw 109vh var(--star-color), 55vw 12vh var(--star-color),
    120vw 178vh var(--star-color), 162vw 71vh var(--star-color),
    172vw 134vh var(--star-color), 50vw 23vh var(--star-color),
    51vw 151vh var(--star-color), 7vw 157vh var(--star-color),
    34vw 45vh var(--star-color), 41vw 14vh var(--star-color),
    182vw 112vh var(--star-color), 118vw 138vh var(--star-color),
    115vw 164vh var(--star-color), 184vw 194vh var(--star-color),
    4vw 96vh var(--star-color), 141vw 149vh var(--star-color),
    113vw 31vh var(--star-color), 145vw 89vh var(--star-color),
    143vw 54vh var(--star-color), 54vw 115vh var(--star-color),
    172vw 83vh var(--star-color), 174vw 143vh var(--star-color),
    132vw 104vh var(--star-color), 31vw 85vh var(--star-color),
    176vw 69vh var(--star-color), 50vw 152vh var(--star-color),
    80vw 66vh var(--star-color), 76vw 43vh var(--star-color),
    137vw 162vh var(--star-color), 49vw 22vh var(--star-color),
    146vw 83vh var(--star-color), 91vw 152vh var(--star-color),
    35vw 53vh var(--star-color), 49vw 196vh var(--star-color),
    39vw 58vh var(--star-color), 29vw 49vh var(--star-color),
    135vw 65vh var(--star-color), 187vw 38vh var(--star-color),
    137vw 105vh var(--star-color), 69vw 34vh var(--star-color),
    114vw 102vh var(--star-color), 102vw 117vh var(--star-color),
    125vw 34vh var(--star-color), 72vw 60vh var(--star-color),
    86vw 33vh var(--star-color), 40vw 112vh var(--star-color),
    82vw 86vh var(--star-color), 147vw 139vh var(--star-color),
    32vw 11vh var(--star-color), 20vw 40vh var(--star-color),
    168vw 61vh var(--star-color), 90vw 38vh var(--star-color),
    58vw 172vh var(--star-color), 59vw 13vh var(--star-color),
    2vw 195vh var(--star-color), 77vw 86vh var(--star-color),
    140vw 64vh var(--star-color), 48vw 34vh var(--star-color),
    170vw 90vh var(--star-color), 78vw 83vh var(--star-color),
    80vw 49vh var(--star-color), 12vw 109vh var(--star-color),
    160vw 107vh var(--star-color), 136vw 25vh var(--star-color),
    71vw 103vh var(--star-color), 141vw 5vh var(--star-color),
    42vw 163vh var(--star-color), 2vw 61vh var(--star-color),
    188vw 51vh var(--star-color), 199vw 179vh var(--star-color),
    133vw 181vh var(--star-color), 120vw 3vh var(--star-color),
    10vw 143vh var(--star-color), 55vw 83vh var(--star-color),
    88vw 120vh var(--star-color), 133vw 196vh var(--star-color),
    107vw 9vh var(--star-color), 182vw 42vh var(--star-color),
    156vw 182vh var(--star-color), 55vw 25vh var(--star-color),
    105vw 89vh var(--star-color), 131vw 11vh var(--star-color),
    32vw 68vh var(--star-color), 61vw 70vh var(--star-color),
    175vw 198vh var(--star-color), 79vw 66vh var(--star-color),
    118vw 55vh var(--star-color), 200vw 70vh var(--star-color),
    88vw 75vh var(--star-color), 36vw 114vh var(--star-color),
    128vw 15vh var(--star-color), 195vw 159vh var(--star-color),
    171vw 120vh var(--star-color), 75vw 58vh var(--star-color),
    182vw 139vh var(--star-color), 100vw 112vh var(--star-color),
    15vw 116vh var(--star-color), 62vw 156vh var(--star-color),
    33vw 196vh var(--star-color), 54vw 96vh var(--star-color),
    8vw 57vh var(--star-color), 98vw 96vh var(--star-color),
    15vw 110vh var(--star-color), 4vw 166vh var(--star-color),
    87vw 17vh var(--star-color), 120vw 43vh var(--star-color),
    51vw 47vh var(--star-color), 124vw 76vh var(--star-color),
    25vw 180vh var(--star-color), 193vw 152vh var(--star-color),
    118vw 81vh var(--star-color), 144vw 15vh var(--star-color),
    93vw 113vh var(--star-color), 79vw 77vh var(--star-color),
    113vw 145vh var(--star-color), 138vw 41vh var(--star-color),
    76vw 13vh var(--star-color), 24vw 184vh var(--star-color),
    71vw 150vh var(--star-color), 43vw 82vh var(--star-color),
    196vw 57vh var(--star-color), 53vw 151vh var(--star-color),
    172vw 109vh var(--star-color), 89vw 158vh var(--star-color),
    139vw 107vh var(--star-color), 95vw 11vh var(--star-color),
    5vw 103vh var(--star-color), 56vw 125vh var(--star-color),
    174vw 37vh var(--star-color), 191vw 167vh var(--star-color),
    157vw 30vh var(--star-color), 100vw 191vh var(--star-color),
    177vw 139vh var(--star-color), 196vw 85vh var(--star-color),
    78vw 142vh var(--star-color), 132vw 112vh var(--star-color),
    119vw 145vh var(--star-color), 103vw 192vh var(--star-color),
    90vw 43vh var(--star-color), 31vw 174vh var(--star-color),
    102vw 134vh var(--star-color), 187vw 37vh var(--star-color),
    172vw 14vh var(--star-color), 119vw 164vh var(--star-color),
    184vw 12vh var(--star-color), 82vw 37vh var(--star-color),
    108vw 83vh var(--star-color), 180vw 75vh var(--star-color),
    84vw 162vh var(--star-color), 167vw 166vh var(--star-color),
    33vw 32vh var(--star-color), 85vw 4vh var(--star-color),
    83vw 92vh var(--star-color), 36vw 147vh var(--star-color),
    58vw 131vh var(--star-color), 133vw 147vh var(--star-color),
    95vw 65vh var(--star-color), 68vw 13vh var(--star-color),
    96vw 107vh var(--star-color), 55vw 30vh var(--star-color),
    39vw 63vh var(--star-color), 77vw 139vh var(--star-color),
    87vw 56vh var(--star-color), 137vw 2vh var(--star-color),
    15vw 3vh var(--star-color), 31vw 162vh var(--star-color),
    152vw 96vh var(--star-color), 87vw 84vh var(--star-color),
    29vw 81vh var(--star-color), 128vw 170vh var(--star-color),
    130vw 157vh var(--star-color), 191vw 110vh var(--star-color),
    81vw 93vh var(--star-color), 74vw 123vh var(--star-color),
    114vw 145vh var(--star-color), 113vw 146vh var(--star-color),
    29vw 158vh var(--star-color), 34vw 194vh var(--star-color),
    10vw 14vh var(--star-color), 42vw 170vh var(--star-color),
    29vw 135vh var(--star-color), 56vw 179vh var(--star-color),
    152vw 106vh var(--star-color), 23vw 32vh var(--star-color),
    173vw 165vh var(--star-color), 158vw 32vh var(--star-color),
    175vw 17vh var(--star-color), 23vw 129vh var(--star-color),
    118vw 198vh var(--star-color), 38vw 71vh var(--star-color),
    52vw 14vh var(--star-color), 45vw 144vh var(--star-color),
    123vw 18vh var(--star-color), 88vw 56vh var(--star-color),
    40vw 175vh var(--star-color), 182vw 25vh var(--star-color),
    144vw 200vh var(--star-color), 163vw 23vh var(--star-color),
    166vw 48vh var(--star-color), 200vw 143vh var(--star-color),
    110vw 17vh var(--star-color), 87vw 7vh var(--star-color),
    48vw 12vh var(--star-color), 99vw 113vh var(--star-color),
    91vw 132vh var(--star-color), 166vw 99vh var(--star-color),
    199vw 125vh var(--star-color), 4vw 28vh var(--star-color),
    189vw 186vh var(--star-color), 177vw 128vh var(--star-color),
    199vw 50vh var(--star-color), 120vw 33vh var(--star-color),
    127vw 88vh var(--star-color), 71vw 159vh var(--star-color),
    166vw 197vh var(--star-color), 10vw 97vh var(--star-color),
    20vw 191vh var(--star-color), 81vw 64vh var(--star-color),
    137vw 166vh var(--star-color), 143vw 111vh var(--star-color),
    96vw 8vh var(--star-color), 9vw 129vh var(--star-color),
    184vw 134vh var(--star-color), 7vw 155vh var(--star-color),
    56vw 47vh var(--star-color), 5vw 65vh var(--star-color),
    70vw 66vh var(--star-color), 15vw 113vh var(--star-color),
    39vw 165vh var(--star-color), 114vw 43vh var(--star-color),
    6vw 190vh var(--star-color), 72vw 92vh var(--star-color),
    54vw 101vh var(--star-color), 9vw 86vh var(--star-color),
    185vw 1vh var(--star-color), 63vw 98vh var(--star-color),
    92vw 174vh var(--star-color), 184vw 84vh var(--star-color),
    102vw 35vh var(--star-color), 117vw 67vh var(--star-color),
    107vw 158vh var(--star-color), 58vw 10vh var(--star-color),
    67vw 35vh var(--star-color), 83vw 17vh var(--star-color),
    16vw 54vh var(--star-color), 151vw 78vh var(--star-color),
    185vw 65vh var(--star-color), 196vw 193vh var(--star-color),
    113vw 169vh var(--star-color), 50vw 64vh var(--star-color),
    176vw 35vh var(--star-color), 33vw 40vh var(--star-color),
    107vw 144vh var(--star-color), 190vw 40vh var(--star-color),
    56vw 13vh var(--star-color), 101vw 106vh var(--star-color),
    127vw 157vh var(--star-color), 57vw 99vh var(--star-color),
    6vw 2vh var(--star-color), 132vw 154vh var(--star-color),
    191vw 88vh var(--star-color), 54vw 113vh var(--star-color),
    188vw 21vh var(--star-color), 6vw 178vh var(--star-color),
    8vw 15vh var(--star-color), 100vw 196vh var(--star-color),
    189vw 189vh var(--star-color), 135vw 141vh var(--star-color),
    147vw 83vh var(--star-color), 46vw 141vh var(--star-color),
    35vw 44vh var(--star-color), 5vw 32vh var(--star-color),
    143vw 148vh var(--star-color), 7vw 38vh var(--star-color),
    140vw 164vh var(--star-color), 5vw 86vh var(--star-color),
    39vw 172vh var(--star-color), 15vw 175vh var(--star-color),
    87vw 135vh var(--star-color), 23vw 12vh var(--star-color),
    10vw 29vh var(--star-color), 26vw 194vh var(--star-color),
    41vw 90vh var(--star-color), 118vw 6vh var(--star-color),
    196vw 34vh var(--star-color), 2vw 90vh var(--star-color),
    4vw 37vh var(--star-color), 179vw 166vh var(--star-color),
    138vw 90vh var(--star-color), 149vw 52vh var(--star-color),
    178vw 27vh var(--star-color), 76vw 117vh var(--star-color),
    77vw 58vh var(--star-color), 135vw 55vh var(--star-color),
    199vw 100vh var(--star-color), 145vw 64vh var(--star-color),
    197vw 150vh var(--star-color), 2vw 174vh var(--star-color),
    93vw 116vh var(--star-color), 175vw 96vh var(--star-color),
    153vw 179vh var(--star-color), 129vw 179vh var(--star-color),
    83vw 164vh var(--star-color), 46vw 135vh var(--star-color),
    93vw 153vh var(--star-color), 9vw 82vh var(--star-color),
    167vw 189vh var(--star-color), 38vw 94vh var(--star-color),
    110vw 9vh var(--star-color), 56vw 189vh var(--star-color),
    136vw 90vh var(--star-color), 122vw 190vh var(--star-color),
    200vw 27vh var(--star-color), 179vw 12vh var(--star-color),
    44vw 138vh var(--star-color), 164vw 122vh var(--star-color),
    43vw 75vh var(--star-color), 164vw 123vh var(--star-color),
    164vw 143vh var(--star-color), 121vw 169vh var(--star-color),
    96vw 88vh var(--star-color), 9vw 18vh var(--star-color),
    128vw 42vh var(--star-color), 170vw 186vh var(--star-color),
    157vw 89vh var(--star-color), 142vw 148vh var(--star-color),
    96vw 149vh var(--star-color), 55vw 33vh var(--star-color),
    6vw 127vh var(--star-color), 143vw 152vh var(--star-color),
    186vw 64vh var(--star-color), 173vw 40vh var(--star-color),
    83vw 16vh var(--star-color), 153vw 155vh var(--star-color),
    64vw 137vh var(--star-color), 132vw 42vh var(--star-color),
    29vw 34vh var(--star-color), 57vw 49vh var(--star-color),
    133vw 154vh var(--star-color), 56vw 66vh var(--star-color),
    123vw 102vh var(--star-color), 5vw 47vh var(--star-color),
    148vw 104vh var(--star-color), 47vw 95vh var(--star-color),
    86vw 113vh var(--star-color), 28vw 10vh var(--star-color),
    153vw 159vh var(--star-color), 132vw 86vh var(--star-color),
    171vw 3vh var(--star-color), 17vw 106vh var(--star-color),
    141vw 168vh var(--star-color), 14vw 40vh var(--star-color),
    122vw 31vh var(--star-color), 91vw 181vh var(--star-color),
    185vw 153vh var(--star-color), 95vw 190vh var(--star-color),
    15vw 92vh var(--star-color), 59vw 106vh var(--star-color),
    123vw 31vh var(--star-color), 125vw 164vh var(--star-color),
    17vw 147vh var(--star-color), 134vw 57vh var(--star-color),
    143vw 149vh var(--star-color), 28vw 9vh var(--star-color),
    148vw 74vh var(--star-color), 138vw 58vh var(--star-color),
    175vw 88vh var(--star-color), 101vw 83vh var(--star-color),
    118vw 11vh var(--star-color), 147vw 114vh var(--star-color),
    59vw 100vh var(--star-color), 53vw 80vh var(--star-color),
    177vw 33vh var(--star-color), 190vw 115vh var(--star-color),
    111vw 66vh var(--star-color), 81vw 86vh var(--star-color),
    157vw 180vh var(--star-color), 152vw 142vh var(--star-color),
    192vw 18vh var(--star-color), 3vw 86vh var(--star-color),
    128vw 145vh var(--star-color), 121vw 72vh var(--star-color),
    154vw 55vh var(--star-color), 79vw 96vh var(--star-color),
    113vw 151vh var(--star-color), 72vw 33vh var(--star-color),
    82vw 159vh var(--star-color), 157vw 12vh var(--star-color),
    157vw 142vh var(--star-color), 37vw 26vh var(--star-color),
    38vw 71vh var(--star-color), 86vw 195vh var(--star-color),
    85vw 196vh var(--star-color), 18vw 189vh var(--star-color),
    31vw 66vh var(--star-color), 158vw 191vh var(--star-color),
    11vw 42vh var(--star-color), 136vw 116vh var(--star-color),
    4vw 180vh var(--star-color), 63vw 1vh var(--star-color),
    162vw 36vh var(--star-color), 96vw 43vh var(--star-color),
    46vw 193vh var(--star-color), 93vw 58vh var(--star-color),
    152vw 159vh var(--star-color), 58vw 102vh var(--star-color),
    185vw 38vh var(--star-color), 35vw 127vh var(--star-color),
    41vw 107vh var(--star-color), 180vw 173vh var(--star-color),
    90vw 127vh var(--star-color), 42vw 13vh var(--star-color),
    72vw 47vh var(--star-color), 101vw 176vh var(--star-color),
    173vw 134vh var(--star-color), 182vw 135vh var(--star-color),
    144vw 180vh var(--star-color), 128vw 32vh var(--star-color),
    142vw 31vh var(--star-color), 85vw 77vh var(--star-color),
    108vw 111vh var(--star-color), 8vw 73vh var(--star-color),
    70vw 27vh var(--star-color), 200vw 102vh var(--star-color),
    5vw 23vh var(--star-color), 159vw 105vh var(--star-color),
    143vw 136vh var(--star-color), 9vw 76vh var(--star-color),
    90vw 65vh var(--star-color), 35vw 53vh var(--star-color),
    147vw 47vh var(--star-color), 49vw 52vh var(--star-color),
    197vw 40vh var(--star-color), 26vw 77vh var(--star-color),
    128vw 195vh var(--star-color), 25vw 12vh var(--star-color),
    89vw 116vh var(--star-color), 117vw 13vh var(--star-color),
    196vw 176vh var(--star-color), 193vw 56vh var(--star-color),
    73vw 22vh var(--star-color), 41vw 89vh var(--star-color),
    111vw 159vh var(--star-color), 111vw 75vh var(--star-color),
    153vw 97vh var(--star-color), 148vw 77vh var(--star-color),
    110vw 46vh var(--star-color), 131vw 163vh var(--star-color),
    134vw 91vh var(--star-color), 122vw 169vh var(--star-color),
    101vw 125vh var(--star-color), 85vw 71vh var(--star-color),
    42vw 9vh var(--star-color), 178vw 186vh var(--star-color),
    155vw 41vh var(--star-color), 100vw 17vh var(--star-color),
    200vw 190vh var(--star-color), 69vw 143vh var(--star-color),
    71vw 123vh var(--star-color), 31vw 165vh var(--star-color),
    134vw 2vh var(--star-color), 91vw 77vh var(--star-color),
    192vw 152vh var(--star-color), 101vw 176vh var(--star-color),
    98vw 141vh var(--star-color), 162vw 51vh var(--star-color),
    5vw 62vh var(--star-color), 73vw 63vh var(--star-color),
    162vw 49vh var(--star-color), 31vw 185vh var(--star-color),
    57vw 79vh var(--star-color), 39vw 71vh var(--star-color),
    125vw 200vh var(--star-color), 190vw 126vh var(--star-color),
    29vw 14vh var(--star-color), 160vw 14vh var(--star-color),
    53vw 40vh var(--star-color), 158vw 109vh var(--star-color);
  animation-duration: var(--stars-sm-speed);
}
.stars-sm:after {
  animation-delay: calc(var(--stars-sm-speed) / -2);
}
.stars-md {
  width: 4px;
  height: 4px;
}
.stars-md:before,
.stars-md:after {
  box-shadow: 132vw 190vh var(--star-color), 29vw 94vh var(--star-color),
    85vw 16vh var(--star-color), 152vw 147vh var(--star-color),
    124vw 155vh var(--star-color), 21vw 91vh var(--star-color),
    155vw 22vh var(--star-color), 176vw 138vh var(--star-color),
    161vw 79vh var(--star-color), 126vw 190vh var(--star-color),
    65vw 122vh var(--star-color), 198vw 44vh var(--star-color),
    29vw 161vh var(--star-color), 70vw 181vh var(--star-color),
    104vw 127vh var(--star-color), 182vw 187vh var(--star-color),
    95vw 156vh var(--star-color), 173vw 82vh var(--star-color),
    176vw 197vh var(--star-color), 56vw 186vh var(--star-color),
    30vw 179vh var(--star-color), 96vw 68vh var(--star-color),
    15vw 35vh var(--star-color), 183vw 94vh var(--star-color),
    68vw 44vh var(--star-color), 38vw 60vh var(--star-color),
    78vw 168vh var(--star-color), 183vw 152vh var(--star-color),
    190vw 24vh var(--star-color), 95vw 94vh var(--star-color),
    114vw 179vh var(--star-color), 174vw 60vh var(--star-color),
    18vw 188vh var(--star-color), 173vw 121vh var(--star-color),
    164vw 2vh var(--star-color), 83vw 142vh var(--star-color),
    50vw 146vh var(--star-color), 73vw 188vh var(--star-color),
    62vw 118vh var(--star-color), 170vw 29vh var(--star-color),
    94vw 34vh var(--star-color), 16vw 56vh var(--star-color),
    83vw 22vh var(--star-color), 174vw 123vh var(--star-color),
    82vw 170vh var(--star-color), 61vw 16vh var(--star-color),
    20vw 120vh var(--star-color), 179vw 177vh var(--star-color),
    136vw 143vh var(--star-color), 104vw 119vh var(--star-color),
    16vw 82vh var(--star-color), 103vw 38vh var(--star-color),
    70vw 67vh var(--star-color), 4vw 109vh var(--star-color),
    169vw 148vh var(--star-color), 150vw 199vh var(--star-color),
    174vw 86vh var(--star-color), 148vw 47vh var(--star-color),
    67vw 160vh var(--star-color), 170vw 98vh var(--star-color),
    95vw 130vh var(--star-color), 151vw 7vh var(--star-color),
    29vw 182vh var(--star-color), 153vw 156vh var(--star-color),
    200vw 183vh var(--star-color), 166vw 118vh var(--star-color),
    78vw 129vh var(--star-color), 88vw 35vh var(--star-color),
    23vw 32vh var(--star-color), 24vw 62vh var(--star-color),
    39vw 60vh var(--star-color), 96vw 102vh var(--star-color),
    186vw 175vh var(--star-color), 80vw 143vh var(--star-color),
    31vw 1vh var(--star-color), 60vw 18vh var(--star-color),
    33vw 174vh var(--star-color), 163vw 165vh var(--star-color),
    33vw 166vh var(--star-color), 157vw 147vh var(--star-color),
    107vw 168vh var(--star-color), 69vw 10vh var(--star-color),
    83vw 78vh var(--star-color), 147vw 51vh var(--star-color),
    131vw 10vh var(--star-color), 200vw 90vh var(--star-color),
    170vw 36vh var(--star-color), 40vw 37vh var(--star-color),
    99vw 89vh var(--star-color), 122vw 42vh var(--star-color),
    45vw 178vh var(--star-color), 12vw 58vh var(--star-color),
    144vw 16vh var(--star-color), 56vw 190vh var(--star-color),
    106vw 74vh var(--star-color), 5vw 154vh var(--star-color),
    55vw 105vh var(--star-color), 198vw 124vh var(--star-color),
    118vw 5vh var(--star-color), 39vw 177vh var(--star-color),
    71vw 65vh var(--star-color), 31vw 12vh var(--star-color),
    192vw 89vh var(--star-color), 128vw 115vh var(--star-color),
    160vw 85vh var(--star-color), 183vw 30vh var(--star-color),
    114vw 114vh var(--star-color), 75vw 150vh var(--star-color),
    87vw 116vh var(--star-color), 125vw 103vh var(--star-color),
    151vw 199vh var(--star-color), 129vw 11vh var(--star-color),
    198vw 20vh var(--star-color), 44vw 145vh var(--star-color),
    66vw 63vh var(--star-color), 144vw 64vh var(--star-color),
    170vw 134vh var(--star-color), 52vw 125vh var(--star-color),
    21vw 132vh var(--star-color), 150vw 136vh var(--star-color),
    127vw 5vh var(--star-color), 16vw 22vh var(--star-color),
    101vw 156vh var(--star-color), 165vw 155vh var(--star-color),
    195vw 11vh var(--star-color), 10vw 55vh var(--star-color),
    66vw 42vh var(--star-color), 87vw 23vh var(--star-color),
    63vw 147vh var(--star-color), 22vw 22vh var(--star-color),
    73vw 174vh var(--star-color), 200vw 86vh var(--star-color),
    114vw 85vh var(--star-color), 68vw 70vh var(--star-color),
    39vw 122vh var(--star-color), 83vw 146vh var(--star-color),
    59vw 67vh var(--star-color), 90vw 132vh var(--star-color),
    102vw 7vh var(--star-color), 196vw 92vh var(--star-color),
    75vw 164vh var(--star-color), 32vw 3vh var(--star-color),
    94vw 143vh var(--star-color), 120vw 126vh var(--star-color),
    30vw 37vh var(--star-color), 192vw 46vh var(--star-color),
    104vw 176vh var(--star-color), 85vw 129vh var(--star-color),
    103vw 130vh var(--star-color), 160vw 47vh var(--star-color),
    66vw 59vh var(--star-color), 199vw 110vh var(--star-color),
    39vw 192vh var(--star-color), 158vw 99vh var(--star-color),
    63vw 168vh var(--star-color), 151vw 194vh var(--star-color),
    188vw 151vh var(--star-color), 108vw 183vh var(--star-color),
    109vw 30vh var(--star-color), 135vw 5vh var(--star-color),
    19vw 70vh var(--star-color), 40vw 185vh var(--star-color),
    19vw 16vh var(--star-color), 129vw 71vh var(--star-color),
    195vw 108vh var(--star-color), 35vw 71vh var(--star-color),
    11vw 188vh var(--star-color), 196vw 199vh var(--star-color),
    20vw 87vh var(--star-color), 100vw 52vh var(--star-color),
    52vw 25vh var(--star-color), 60vw 110vh var(--star-color),
    137vw 10vh var(--star-color), 62vw 129vh var(--star-color),
    35vw 60vh var(--star-color), 199vw 151vh var(--star-color),
    60vw 197vh var(--star-color), 116vw 69vh var(--star-color),
    149vw 37vh var(--star-color), 45vw 148vh var(--star-color),
    26vw 200vh var(--star-color), 65vw 72vh var(--star-color),
    6vw 75vh var(--star-color), 111vw 197vh var(--star-color),
    59vw 49vh var(--star-color), 193vw 187vh var(--star-color),
    94vw 49vh var(--star-color), 97vw 185vh var(--star-color),
    152vw 31vh var(--star-color), 132vw 59vh var(--star-color),
    171vw 97vh var(--star-color), 139vw 62vh var(--star-color),
    141vw 72vh var(--star-color), 132vw 54vh var(--star-color),
    133vw 61vh var(--star-color), 50vw 92vh var(--star-color),
    169vw 141vh var(--star-color), 117vw 102vh var(--star-color),
    75vw 102vh var(--star-color), 140vw 126vh var(--star-color);
  animation-duration: var(--stars-md-speed);
}
.stars-md:after {
  animation-delay: calc(var(--stars-md-speed) / -2);
}
.stars-lg {
  width: 8px;
  height: 8px;
}
.stars-lg:before,
.stars-lg:after {
  box-shadow: 28vw 28vh var(--star-color), 107vw 28vh var(--star-color),
    36vw 34vh var(--star-color), 193vw 181vh var(--star-color),
    168vw 109vh var(--star-color), 85vw 156vh var(--star-color),
    116vw 145vh var(--star-color), 180vw 39vh var(--star-color),
    191vw 145vh var(--star-color), 36vw 41vh var(--star-color),
    154vw 170vh var(--star-color), 93vw 164vh var(--star-color),
    194vw 109vh var(--star-color), 135vw 49vh var(--star-color),
    65vw 17vh var(--star-color), 118vw 146vh var(--star-color),
    153vw 127vh var(--star-color), 27vw 103vh var(--star-color),
    18vw 100vh var(--star-color), 11vw 15vh var(--star-color),
    140vw 76vh var(--star-color), 110vw 168vh var(--star-color),
    72vw 8vh var(--star-color), 42vw 164vh var(--star-color),
    8vw 132vh var(--star-color), 88vw 159vh var(--star-color),
    86vw 196vh var(--star-color), 36vw 52vh var(--star-color),
    141vw 100vh var(--star-color), 56vw 191vh var(--star-color),
    48vw 194vh var(--star-color), 144vw 42vh var(--star-color),
    154vw 75vh var(--star-color), 177vw 14vh var(--star-color),
    146vw 42vh var(--star-color), 134vw 139vh var(--star-color),
    4vw 124vh var(--star-color), 167vw 158vh var(--star-color),
    146vw 4vh var(--star-color), 159vw 9vh var(--star-color),
    72vw 163vh var(--star-color), 132vw 131vh var(--star-color),
    89vw 104vh var(--star-color), 45vw 44vh var(--star-color),
    130vw 136vh var(--star-color), 21vw 68vh var(--star-color),
    187vw 132vh var(--star-color), 67vw 40vh var(--star-color),
    188vw 110vh var(--star-color), 183vw 159vh var(--star-color);
  animation-duration: var(--stars-lg-speed);
}
.stars-lg:after {
  animation-delay: calc(var(--stars-lg-speed) / -2);
}

@keyframes stars {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.time-chart {
  width: calc(100% - 140px);
  position: absolute;
  top: 90px;
  left: 0;
  height: 140px;
}

.ship {
  bottom: 30px;
  right: 70px;
  left: unset;
  transform: rotate(50deg);
  zoom: 1;
  transition: all 4s;
}

@keyframes MoveUpDown {
  0%,
  100% {
    bottom: 20px;
  }
  50% {
    bottom: 40px;
  }
}

@keyframes exhaust {
  to {
    transform: scaleX(0.98) translateY(-1vmin);
  }
}
@keyframes speed-up-exhaust {
  from {
    transform: scale(0.98, 1);
  }
  to {
    transform: scale(0.96, 1.5);
  }
}
@keyframes speed-up-ship {
  from {
    transform: translateY(-5%);
  }
  to {
    transform: translateY(-3%);
  }
}
